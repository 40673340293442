import './scss/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { RouterGuard } from 'react-router-guard';
import config from './config';

const App = () => {
  return (
    <RouterGuard config={config} />
  );
};

ReactDOM.render(<App />, document.getElementById('root'));
